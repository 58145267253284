<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" :title="title" :showBack="true" :noboder="$store.state.isPC ? true : false"></topbar>

      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content">
            <div class="title-PC" v-if="$store.state.isPC">{{ title }}</div>
            <div class="form-item">
              <label>Password</label>
              <div class="input-box password">
                <input :type="ispassword ? 'password' : 'text'" v-model="$store.state.userInfo.password" maxlength="15"
                  placeholder="Password" @blur="checkPassword" @input="errorPassword = false" />
                <i class="icon-eye" @click="ispassword = false" v-if="ispassword"></i>
                <i class="icon-eye-blocked" @click="ispassword = true" v-else></i>
              </div>
              <p class="tip" :class="{ red: errorPassword }">8-15 characters with letters, numbers and special characters,
                case sensitive</p>
            </div>
            <div class="form-item">
              <label>Confirm Password</label>
              <div class="input-box password">
                <input :type="ispassword2 ? 'password' : 'text'" v-model="confirmPassword" maxlength="15"
                  placeholder="Confirm Password" @input="errorConfirmPassword = false" @blur="checkConfirmPassword" />
                <i class="icon-eye" @click="ispassword2 = false" v-if="ispassword2"></i>
                <i class="icon-eye-blocked" @click="ispassword2 = true" v-else></i>
              </div>
              <p class="tip red" v-if="errorConfirmPassword">Passwords do not match</p>
            </div>
            <div class="item-table flex-center">
              <div class="tdcell checkbox f-s-0">
                <van-checkbox v-model="agreeTC" shape="square" checked-color="#00a1de"></van-checkbox>
              </div>
              <div class="tdcell">
                I have read and agree to the
                <span class="sub-color" @click="goTAndC">Terms & Conditions</span>
                and
                <span class="sub-color" @click="goPrivacy">Privacy Policy</span>
              </div>
            </div>
            <div class="item-table flex-center pad-t-20">
              <div class="tdcell checkbox f-s-0">
                <van-checkbox v-model="$store.state.userInfo.isMarketingInfo" shape="square"
                  checked-color="#00a1de"></van-checkbox>
              </div>
              <div class="tdcell">I would like to receive online specials / promotions comms.</div>
            </div>
            <div class="pad-t-60">
              <ion-button expand="block" @click="confirmEvent"
                :disabled="!(agreeTC && $store.state.userInfo.password && confirmPassword && !(errorPassword || errorConfirmPassword))">Continue</ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import config from "../config";
import topbar from "@/components/topbar.vue";
export default {
  name: "createAccount",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonButton },
  data() {
    return {
      isEmail: false,
      checked: false,
      confirmPassword: "",
      rightName: false,
      errorName: false,
      ispassword: true,
      ispassword2: true,
      errorPassword: false,
      errorConfirmPassword: false,
      agreeTC: false,
      isExist: false,
      title: "Create Account",
    };
  },
  methods: {
    confirmEvent() {
      this.$router.push({
        path: "/setProfile",
      });
      this.sendGAEvent("Click", this.title, "Confirm");
    },
    checkUserName() {
      this.isExist = false;
      const rule = /^[a-zA-Z\d]{6,12}$/;
      if (rule.test(this.$store.state.userInfo.name)) {
        this.rightName = true;
        this.errorName = false;
        this.checkIsExistUserNameEvent();
      } else {
        this.rightName = false;
        this.errorName = true;
      }
    },
    async checkIsExistUserNameEvent() {
      const objdata = this.getAuthObj();
      objdata.name = this.$store.state.userInfo.name;
      objdata.apiUrl = this.apiConfig.checkIsExistUserName;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (data.data.result) {
        this.isExist = true;
      }
    },
    checkPassword() {
      if (!this.$store.state.passwordPatrn.test(this.$store.state.userInfo.password)) {
        this.errorPassword = true;
      }
      if (this.confirmPassword) {
        this.$forceUpdate();
        this.checkConfirmPassword();
      }
    },
    checkConfirmPassword() {
      if (this.confirmPassword == '' || this.confirmPassword == null) {
        return
      }
      if (this.$store.state.userInfo.password != this.confirmPassword) {
        this.errorConfirmPassword = true;
      } else {
        this.errorConfirmPassword = false;
      }
    },
    goTAndC() {
      this.$router.push({
        path: "/Terms",
      });
      this.sendGAEvent("Click", this.title, "Terms & Conditions");
    },
    goPrivacy() {
      this.$router.push({
        path: "/privacy",
      });
      this.sendGAEvent("Click", this.title, "Privacy Policy");
    },
  },
  ionViewWillEnter() {
    this.title = "Create Account";
    this.$store.state.userInfo.isMarketingInfo = true;
    this.$store.state.userInfo.physicalCardNumber = "";
    if (this.$store.state.verifyUserInfo) {
      if (this.$store.state.verifyUserInfo.email && this.$store.state.verifyUserInfo.email.trim().toLowerCase() != this.$store.state.userInfo.email.trim().toLowerCase()) {
        this.$store.state.verifyUserInfo = null;
      } else {
        this.$store.state.userInfo.isMarketingInfo = this.$store.state.verifyUserInfo.isMarketingInfo;
        this.$store.state.userInfo.physicalCardNumber = this.$store.state.verifyUserInfo.physicalCardNumber;
        this.title = "Reset Password";
      }
    }
    this.$store.state.exitApp = false;

    this.isEmail = false;
    this.checked = false;
    this.confirmPassword = "";
    this.rightName = false;
    this.errorName = false;
    this.ispassword = true;
    this.ispassword2 = true;
    this.errorPassword = false;
    this.errorConfirmPassword = false;
    this.agreeTC = true;
    this.isExist = false;
    this.sendGAPageView(this.title);
  },
};
</script>
